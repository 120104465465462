@import "~bootstrap/scss/functions";

/* Customizations */
// $primary: #EB943E;
$primary: #ffac58;
$body-bg: tint-color($primary, 80);
$input-bg: white;

/* Import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';
